
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

.hero {
	background: url('/images/banners/home/home-2.jpg') no-repeat center;
	background-size: cover;
	padding: $block-padding 0 0;

	.hero-inner {
		position: relative;
		height: 100%;
		height: 30rem;

		@media #{$tabletXL} {
			height: 60rem;
		}
	}

	.company-logo {
		width: 300px;
		padding: 0;
		
		@media #{$tablet} {
			width: 250px;
			position: absolute;
			left: 50px;
			padding: ($block-padding * 2) 0;
		}

		@media #{$tabletXL} {
			width: 300px;
		}

		@media #{$tabletXL} {
			left: 100px;
		}

		@media #{$desktop} {
			left: 135px;
		}
	}

	.services-individuals-button, .services-business-button, .estate-agents-button {
		@extend %uppercase-text;
		position: absolute;
		border-radius: 50%;
		text-align: center;
		transition: t(default);
		width: 35vw;
		height: 35vw;
		bottom: $block-padding * 2;

		&:hover {
			transform: scale(1.1);
			transition: t(default);
			cursor: pointer;
		}

		p {
			&:first-child {
				text-transform: capitalize;
			}
		}

		a {
			@include centre;
			color: $color-white;
		}
	}

	.services-individuals-button, .services-business-button, .enquiry-button, .estate-agents-button {

		@media #{$mobileL} {
			display: block;
			width: 25vw;
			height: 25vw;
			bottom: $block-padding;
			top: auto;
		}
	}

	.services-individuals-button {
		background: $color-tertiary;
		left: 10vw;

		@media #{$mobileL} {
			left: 7.5vw;
		}

		@media #{$tablet} {
			width: 140px;
			height: 140px;
			right: 23%;
			top: 5%;
			left: auto;
		}

		@media #{$tabletXL} {
			width: 160px;
			height: 160px;
		}

		@media #{$laptop} {
			width: 200px;
			height: 200px;
			right: 25%;
		}
	}

	.services-business-button {
		background: $color-quaternary;
		right: 10vw;

		@media #{$mobileL} {
			right: 7.5vw;
		}

		@media #{$tablet} {
			width: 160px;
			height: 160px;
			right: calc(23% + #{$block-padding * 3});
			top: 35%;
			left: auto;
		}
		
		@media #{$tabletXL} {
			width: 180px;
			height: 180px;
			top: 23%;
		}

		@media #{$laptop} {
			width: 230px;
			height: 230px;
			right: calc(25% + #{$block-padding * 4});
			top: 25%;
		}
	}

	.enquiry-button {

		@media #{$mobileL} {
			left: 37.5vw;
		}

		@media #{$tablet} {
			width: 120px;
			height: 120px;
			right: calc(23% + #{$block-padding * 7});
			top: 10%;
			left: auto;
		}

		@media #{$tabletXL} {
			right: calc(23% + #{$block-padding * 8});
		}

		@media #{$laptop} {
			width: 140px;
			height: 140px;
			right: calc(25% + #{$block-padding * 10});
		}
	}

	.estate-agents-button {
		// background: $color-white url('/cms/photo/logos/estate_agents_button.png') no-repeat center center;
		background: $color-primary;
		overflow: hidden;
		@extend .hidden;

		@media #{$mobileL} {
			left: 37.5vw;
			display: none;
		}

		@media #{$mobileXL} {
			display: none;
		}

		@media #{$tablet} {
			width: 130px;
			height: 130px;
			right: calc(0% + #{$block-padding * 5});
			top: 34%;
			left: auto;
			display: block;
		}

		@media #{$tabletXL} {
			width: 160px;
			height: 160px;
			right: calc(0% + #{$block-padding * 4});
			top: 26%;
		}

		@media #{$laptop} {
			width: 170px;
			height: 170px;
			right: calc(0% + #{$block-padding * 9});
		}

		.button-image {
			@include centreX();
			width: 100%;
		}
	}

	.home-tagline {
		text-align: center;
		padding: $block-padding;
		color: $color-secondary;

		@media #{$tablet} {
			position: absolute;
			width: calc(100% * 1/3);
			left: 0;
			top: 50%;
			text-align: right;
		}

		@media #{$tabletXL} {
			top: 30%;
		}
	}

	.home-hero-panel {
		position: relative;
		background: rgba($color-secondary, 0.8);
		display: block;
		padding: 0;
		z-index: z(front) + 5;

		@media #{$tabletXL} {
			position: absolute;
			bottom: 0;
			right: 0;
			left: 0;
		}

		&:before {
			@media #{$tabletXL} {
				content: '';
				display: block;
				background: $color-secondary;
				position: absolute;
				left: 0;
				width: 35%;
				bottom: 0;
				top: 0;
				z-index: z(back);
			}
		}
		
		.inner {
			@extend %clear-fix;
			height: 100%;
			display: block;
			min-height: 18rem;
			position: relative;
		}
	}

	.home-text-rotator, .home-enquiry-form{
		padding: $block-padding;
		margin-bottom: 0;

		@media #{$tablet} {
			float: left;
			padding: $block-padding * 2;
		}
	}

	.home-text-rotator {
		background: $color-secondary;

		@media #{$tablet} {
			width: 50%;
			min-height: 370px;
		}	

		@media #{$laptop} {
			width: calc(100% * 1/3);

		}	
	}

	.home-enquiry-form {
		position: relative;
		text-align: center;
		color: $color-white;
		@include placeholder($color-white);
		padding-bottom: 0;

		@media #{$tablet} {
			width: 50%;
			position: absolute;
			left: 50%;
			bottom: 0;
		}

		@media #{$laptop} {
			width: calc(100% * 2/3);
			left: calc(100% * 1/3);
		}

		 p {

		 	&:nth-child(1) {

		 	}

		 	&:nth-child(2) {
		 		font-size: 13px
		 	}
		 }

		.content {
			max-width: 35rem;
			margin: 0 auto;
		}

		&.active {
			background: $color-secondary;
		}
		
		.h3 {
			color: $color-white;
		}

		.input, .textarea {
			color: $color-white;
		}

		.textarea {
			height: 90px;
		}

		.input, .textarea, .select {
			background: lighten($color-secondary, 15%);
			border: none;
		}

		.select {
			color: $color-white;

			option {
				background: $color-white;
				color: $color-black;
			}
		}

		.form-enquiry-home {
			left: $block-padding;
			right: $block-padding;
		}

		.submit-row {
			bottom: - 2.5rem;

			.submit {
				background: $color-primary;
				font-size: 0.9rem;

				&:hover {
					background: $color-tertiary;
				}
			}
		}

		.gdpr-notice {
			margin-top: .5rem !important;
			margin-bottom: 0;
			padding-bottom: 0;

			a {
				color: $color-primary;

				&:hover {
					color: $color-white;
				}
			}
		}

		.advert-form-close {
			@extend .hidden;
			position: absolute;
			left: 0;
			top: 0;
			width: 50px;
			height: 50px;
			background: $color-secondary-dark;
			transition: all t(default) ease-out;
			z-index: z-index(front);
			cursor: pointer;

			@media #{$tabletXL} {
				z-index: z-index(front);
			}

			.icon {
				@include centre(none);
				fill: $color-white;
			}
		}

		&.open {

			.advert-form-close {
				transform: translateX(-100%);
				transition-delay: .2s;

				@media #{$tabletXL} {
					transform: translateX(-100%);
				}
			}
		}
	}
}

.main {
	max-width: 100%;
	padding: 0;
	background: none;

	@media #{$tablet} {
		&:before {
			content: '';
			width: 50%;
			position: absolute;
			background: $color-lightgrey;
			left: 0;
			top: 0;
			bottom: 0;
		}
	}

	@media #{$laptop} {
		&:before {
			content: '';
			width: 35%;
			position: absolute;
			background: $color-lightgrey;
			left: 0;
			top: 0;
			bottom: 0;
		}
	}


	#container-centre {
		max-width: $max-width;
		margin: 0 auto;
	}

	.news-items,
	.advert-rotator {
		width: 100%;
		float: none;
		margin-bottom: 0;
	}

	.advert-rotator {
		border-radius: 0px;	

		@media #{$tablet} {
			@include fraction(1/2);
			padding: ($block-padding * 2);
		}

		@media #{$laptop} {
			@include fraction(1/3);
		}
	}

	.news-items {
		display: block;
		padding: 0;

		@media #{$tablet} {
			@include fraction(1/2);
		}

		@media #{$laptop} {
			border-bottom: none;
			@include fraction(2/3);
		}

		.item {
			padding: ($block-padding * 2);
			margin-bottom: 0;

			@media #{$tablet} {
				width: 100%;
				border-left: none;
				border-bottom: $border;

				&:last-child {
					border-bottom: none;
				}
			}

			@media #{$tabletXL} {
				border-bottom: none;
				border-left: $border;

				&:first-child {
					border-left: none;
				}
			}

			@media #{$laptop} {
				width: 50%;
				height: 100%;
			}
		}
	}
}
.covid-notice{
	margin-bottom: 1.5rem;
    margin: auto;
	width: 100vw;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
	background: #eee;

	h1{
		margin: 1rem 0 0.5rem;
		color: #9aaf24;
	}

	.content{
		width: 90%;
	}

	p{
		color: #394a59;
	}
}

#pre-footer {
	@extend .hidden;
}