html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.hero .home-hero-panel .inner:after {
  content: '';
  clear: both;
  display: block;
  width: 100%;
  overflow: hidden; }

.hidden, .hero .estate-agents-button, .hero .home-enquiry-form .advert-form-close, #pre-footer {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

.hero .services-individuals-button, .hero .services-business-button, .hero .estate-agents-button {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 1px; }

.hero {
  background: url("/images/banners/home/home-2.jpg") no-repeat center;
  background-size: cover;
  padding: 1.5rem 0 0; }
  .hero .hero-inner {
    position: relative;
    height: 100%;
    height: 30rem; }
    @media only screen and (min-width: 60em) {
      .hero .hero-inner {
        height: 60rem; } }
  .hero .company-logo {
    width: 300px;
    padding: 0; }
    @media only screen and (min-width: 50em) {
      .hero .company-logo {
        width: 250px;
        position: absolute;
        left: 50px;
        padding: 3rem 0; } }
    @media only screen and (min-width: 60em) {
      .hero .company-logo {
        width: 300px; } }
    @media only screen and (min-width: 60em) {
      .hero .company-logo {
        left: 100px; } }
    @media only screen and (min-width: 100em) {
      .hero .company-logo {
        left: 135px; } }
  .hero .services-individuals-button, .hero .services-business-button, .hero .estate-agents-button {
    position: absolute;
    border-radius: 50%;
    text-align: center;
    transition: 0.3s;
    width: 35vw;
    height: 35vw;
    bottom: 3rem; }
    .hero .services-individuals-button:hover, .hero .services-business-button:hover, .hero .estate-agents-button:hover {
      transform: scale(1.1);
      transition: 0.3s;
      cursor: pointer; }
    .hero .services-individuals-button p:first-child, .hero .services-business-button p:first-child, .hero .estate-agents-button p:first-child {
      text-transform: capitalize; }
    .hero .services-individuals-button a, .hero .services-business-button a, .hero .estate-agents-button a {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff; }
  @media only screen and (min-width: 32.5em) {
    .hero .services-individuals-button, .hero .services-business-button, .hero .enquiry-button, .hero .estate-agents-button {
      display: block;
      width: 25vw;
      height: 25vw;
      bottom: 1.5rem;
      top: auto; } }
  .hero .services-individuals-button {
    background: #f2446f;
    left: 10vw; }
    @media only screen and (min-width: 32.5em) {
      .hero .services-individuals-button {
        left: 7.5vw; } }
    @media only screen and (min-width: 50em) {
      .hero .services-individuals-button {
        width: 140px;
        height: 140px;
        right: 23%;
        top: 5%;
        left: auto; } }
    @media only screen and (min-width: 60em) {
      .hero .services-individuals-button {
        width: 160px;
        height: 160px; } }
    @media only screen and (min-width: 70em) {
      .hero .services-individuals-button {
        width: 200px;
        height: 200px;
        right: 25%; } }
  .hero .services-business-button {
    background: #34b5d0;
    right: 10vw; }
    @media only screen and (min-width: 32.5em) {
      .hero .services-business-button {
        right: 7.5vw; } }
    @media only screen and (min-width: 50em) {
      .hero .services-business-button {
        width: 160px;
        height: 160px;
        right: calc(23% + 4.5rem);
        top: 35%;
        left: auto; } }
    @media only screen and (min-width: 60em) {
      .hero .services-business-button {
        width: 180px;
        height: 180px;
        top: 23%; } }
    @media only screen and (min-width: 70em) {
      .hero .services-business-button {
        width: 230px;
        height: 230px;
        right: calc(25% + 6rem);
        top: 25%; } }
  @media only screen and (min-width: 32.5em) {
    .hero .enquiry-button {
      left: 37.5vw; } }
  @media only screen and (min-width: 50em) {
    .hero .enquiry-button {
      width: 120px;
      height: 120px;
      right: calc(23% + 10.5rem);
      top: 10%;
      left: auto; } }
  @media only screen and (min-width: 60em) {
    .hero .enquiry-button {
      right: calc(23% + 12rem); } }
  @media only screen and (min-width: 70em) {
    .hero .enquiry-button {
      width: 140px;
      height: 140px;
      right: calc(25% + 15rem); } }
  .hero .estate-agents-button {
    background: #bcd531;
    overflow: hidden; }
    @media only screen and (min-width: 32.5em) {
      .hero .estate-agents-button {
        left: 37.5vw;
        display: none; } }
    @media only screen and (min-width: 40em) {
      .hero .estate-agents-button {
        display: none; } }
    @media only screen and (min-width: 50em) {
      .hero .estate-agents-button {
        width: 130px;
        height: 130px;
        right: calc(0% + 7.5rem);
        top: 34%;
        left: auto;
        display: block; } }
    @media only screen and (min-width: 60em) {
      .hero .estate-agents-button {
        width: 160px;
        height: 160px;
        right: calc(0% + 6rem);
        top: 26%; } }
    @media only screen and (min-width: 70em) {
      .hero .estate-agents-button {
        width: 170px;
        height: 170px;
        right: calc(0% + 13.5rem); } }
    .hero .estate-agents-button .button-image {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%; }
  .hero .home-tagline {
    text-align: center;
    padding: 1.5rem;
    color: #394a59; }
    @media only screen and (min-width: 50em) {
      .hero .home-tagline {
        position: absolute;
        width: calc(100% * 1/3);
        left: 0;
        top: 50%;
        text-align: right; } }
    @media only screen and (min-width: 60em) {
      .hero .home-tagline {
        top: 30%; } }
  .hero .home-hero-panel {
    position: relative;
    background: rgba(57, 74, 89, 0.8);
    display: block;
    padding: 0;
    z-index: 6; }
    @media only screen and (min-width: 60em) {
      .hero .home-hero-panel {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0; } }
    @media only screen and (min-width: 60em) {
      .hero .home-hero-panel:before {
        content: '';
        display: block;
        background: #394a59;
        position: absolute;
        left: 0;
        width: 35%;
        bottom: 0;
        top: 0;
        z-index: -1; } }
    .hero .home-hero-panel .inner {
      height: 100%;
      display: block;
      min-height: 18rem;
      position: relative; }
  .hero .home-text-rotator, .hero .home-enquiry-form {
    padding: 1.5rem;
    margin-bottom: 0; }
    @media only screen and (min-width: 50em) {
      .hero .home-text-rotator, .hero .home-enquiry-form {
        float: left;
        padding: 3rem; } }
  .hero .home-text-rotator {
    background: #394a59; }
    @media only screen and (min-width: 50em) {
      .hero .home-text-rotator {
        width: 50%;
        min-height: 370px; } }
    @media only screen and (min-width: 70em) {
      .hero .home-text-rotator {
        width: calc(100% * 1/3); } }
  .hero .home-enquiry-form {
    position: relative;
    text-align: center;
    color: #fff;
    padding-bottom: 0; }
    .hero .home-enquiry-form ::-webkit-input-placeholder {
      color: #fff; }
    .hero .home-enquiry-form :-moz-placeholder {
      color: #fff; }
    .hero .home-enquiry-form ::-moz-placeholder {
      color: #fff; }
    .hero .home-enquiry-form :-ms-input-placeholder {
      color: #fff; }
    @media only screen and (min-width: 50em) {
      .hero .home-enquiry-form {
        width: 50%;
        position: absolute;
        left: 50%;
        bottom: 0; } }
    @media only screen and (min-width: 70em) {
      .hero .home-enquiry-form {
        width: calc(100% * 2/3);
        left: calc(100% * 1/3); } }
    .hero .home-enquiry-form p:nth-child(2) {
      font-size: 13px; }
    .hero .home-enquiry-form .content {
      max-width: 35rem;
      margin: 0 auto; }
    .hero .home-enquiry-form.active {
      background: #394a59; }
    .hero .home-enquiry-form .h3 {
      color: #fff; }
    .hero .home-enquiry-form .input, .hero .home-enquiry-form .textarea {
      color: #fff; }
    .hero .home-enquiry-form .textarea {
      height: 90px; }
    .hero .home-enquiry-form .input, .hero .home-enquiry-form .textarea, .hero .home-enquiry-form .select {
      background: #577188;
      border: none; }
    .hero .home-enquiry-form .select {
      color: #fff; }
      .hero .home-enquiry-form .select option {
        background: #fff;
        color: #111; }
    .hero .home-enquiry-form .form-enquiry-home {
      left: 1.5rem;
      right: 1.5rem; }
    .hero .home-enquiry-form .submit-row {
      bottom: -2.5rem; }
      .hero .home-enquiry-form .submit-row .submit {
        background: #bcd531;
        font-size: 0.9rem; }
        .hero .home-enquiry-form .submit-row .submit:hover {
          background: #f2446f; }
    .hero .home-enquiry-form .gdpr-notice {
      margin-top: .5rem !important;
      margin-bottom: 0;
      padding-bottom: 0; }
      .hero .home-enquiry-form .gdpr-notice a {
        color: #bcd531; }
        .hero .home-enquiry-form .gdpr-notice a:hover {
          color: #fff; }
    .hero .home-enquiry-form .advert-form-close {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 50px;
      background: #2f3d49;
      transition: all 0.3s ease-out;
      z-index: 1;
      cursor: pointer; }
      @media only screen and (min-width: 60em) {
        .hero .home-enquiry-form .advert-form-close {
          z-index: 1; } }
      .hero .home-enquiry-form .advert-form-close .icon {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: #fff; }
    .hero .home-enquiry-form.open .advert-form-close {
      transform: translateX(-100%);
      transition-delay: .2s; }
      @media only screen and (min-width: 60em) {
        .hero .home-enquiry-form.open .advert-form-close {
          transform: translateX(-100%); } }

.main {
  max-width: 100%;
  padding: 0;
  background: none; }
  @media only screen and (min-width: 50em) {
    .main:before {
      content: '';
      width: 50%;
      position: absolute;
      background: #eee;
      left: 0;
      top: 0;
      bottom: 0; } }
  @media only screen and (min-width: 70em) {
    .main:before {
      content: '';
      width: 35%;
      position: absolute;
      background: #eee;
      left: 0;
      top: 0;
      bottom: 0; } }
  .main #container-centre {
    max-width: 1300px;
    margin: 0 auto; }
  .main .news-items,
  .main .advert-rotator {
    width: 100%;
    float: none;
    margin-bottom: 0; }
  .main .advert-rotator {
    border-radius: 0px; }
    @media only screen and (min-width: 50em) {
      .main .advert-rotator {
        float: left;
        width: 50%;
        padding: 3rem; } }
    @media only screen and (min-width: 70em) {
      .main .advert-rotator {
        float: left;
        width: 33.33333%; } }
  .main .news-items {
    display: block;
    padding: 0; }
    @media only screen and (min-width: 50em) {
      .main .news-items {
        float: left;
        width: 50%; } }
    @media only screen and (min-width: 70em) {
      .main .news-items {
        border-bottom: none;
        float: left;
        width: 66.66667%; } }
    .main .news-items .item {
      padding: 3rem;
      margin-bottom: 0; }
      @media only screen and (min-width: 50em) {
        .main .news-items .item {
          width: 100%;
          border-left: none;
          border-bottom: 1px solid #eee; }
          .main .news-items .item:last-child {
            border-bottom: none; } }
      @media only screen and (min-width: 60em) {
        .main .news-items .item {
          border-bottom: none;
          border-left: 1px solid #eee; }
          .main .news-items .item:first-child {
            border-left: none; } }
      @media only screen and (min-width: 70em) {
        .main .news-items .item {
          width: 50%;
          height: 100%; } }

.covid-notice {
  margin-bottom: 1.5rem;
  margin: auto;
  width: 100vw;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  background: #eee; }
  .covid-notice h1 {
    margin: 1rem 0 0.5rem;
    color: #9aaf24; }
  .covid-notice .content {
    width: 90%; }
  .covid-notice p {
    color: #394a59; }
